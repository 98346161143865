/* App.css */

.App {
  min-height: 100vh;
  background-color: #f0f2f5;
  /* make it horizontal center on desktop */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }
}

.step-card {
  transition: border-color 0.3s, box-shadow 0.3s;
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 16px;
}

.step-card.completed {
  border-color: #1890ff;
  box-shadow: 0 0 10px rgba(24, 144, 255, 0.5);
}

.step-title {
  background-color: #f5f5f5;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.step-description {
  margin-bottom: 16px;
}

.cell {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.cell-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  text-align: left;
}

.input-element,
.select-element,
.date-picker {
  width: 100%;
}

.copy-textarea {
  resize: none;
}

.completion-checkbox {
  margin-top: 16px;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 8px;
}

.button-element {
  margin-top: 8px;
}

.required-mark {
  color: #ff4d4f;
  margin-left: 4px;
}

.error-message {
  color: #ff4d4f;
  font-size: 14px;
  margin-top: 4px;
}

/* Responsive Design for Mobile Devices */
@media (max-width: 768px) {
  .step-card {
    padding: 12px;
    margin-bottom: 12px;
  }

  .step-title {
    padding: 8px 12px;
    font-size: 18px;
    margin-bottom: 12px;
  }

  .step-description {
    margin-bottom: 12px;
    font-size: 14px;
  }

  .cell {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .cell-label {
    margin-bottom: 4px;
    font-size: 14px;
  }

  .input-element,
  .select-element,
  .date-picker {
    font-size: 16px;
  }

  .copy-textarea {
    font-size: 14px;
    min-height: 60px;
  }

  .completion-checkbox {
    margin-top: 12px;
  }

  .action-button {
    margin-top: 8px;
    font-size: 16px;
  }

  .button-element {
    margin-top: 8px;
    font-size: 16px;
  }

  .ant-btn {
    padding: 8px 16px;
    font-size: 16px;
  }

  .error-message {
    font-size: 12px;
  }
}

/* Additional styles for better mobile experience */
@media (max-width: 480px) {
  .step-card {
    padding: 8px;
  }

  .step-title {
    font-size: 16px;
  }

  .cell-label {
    font-size: 13px;
  }

  .input-element,
  .select-element,
  .date-picker,
  .ant-btn {
    font-size: 14px;
  }
}

/* Ensure form elements are easily tappable on touch devices */
@media (hover: none) and (pointer: coarse) {
  .ant-btn,
  .ant-input,
  .ant-select-selector,
  .ant-picker {
    min-height: 44px;
  }

  .ant-checkbox-wrapper {
    padding: 8px 0;
  }
}
