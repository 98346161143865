.App {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 0px;
  background-color: #f0f2f5;
  min-height: 100vh;
}

.App-header {
  margin: 0 auto;
  padding: 10px;
}

.instructions {
  text-align: left;
}

.comment-box {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background-color: #fafafa;
  resize: none;
  word-wrap: break-word; /* Ensure word wrapping */
}

.step {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
}

textarea:focus {
  outline: none; /* Remove focus outline */
  border-color: #1890ff;
}

/* Prevent Safari from zooming on textarea focus */
textarea,
input,
select {
  font-size: 16px;
}

/* Styling the tweet URL for proper wrapping */
.tweet-url {
  word-break: break-word;
}

button {
  margin-top: 10px;
}
