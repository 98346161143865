.App {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f0f2f5;
  min-height: 100vh;
}

.App-header {
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.instructions {
  text-align: left;
}

.comment-box {
  background-color: #fafafa;
  padding: 15px;
  border-radius: 8px;
  word-break: break-word;
  margin-bottom: 20px;
}

.step {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.tweet-link {
  margin-top: 15px;
}
